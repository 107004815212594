import { VueConstructor } from 'vue'

import { BaseAuth } from './baseAuthUtil'
import Vue from 'vue'

class BackendAuth extends BaseAuth {}

export const backendAuth = new BackendAuth(Vue)
export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$auth = backendAuth
    // Vue.prototype.$auth = new BackendAuth(Vue)
  }
}
